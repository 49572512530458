<template>
    <section class="nds-rules-square"
             v-if="ruleBlocks.length > 0 && isStandMode">
        <div class="nds-rules-squares__wrapper">
            <div
                v-for="item in ruleBlocks"
                class="nds-rules-squares__rule"
            >
                <img v-if="item.iconUrl" :src="item.iconUrl" class="nds-rules-squares__image" />
                <img v-else src="@/assets/img/tournaments/demo-stand/rules-default-icon.svg" class="nds-rules-squares__image" />
                <div class="nds-rules-squares__description" v-html="item.description"></div>
            </div>
        </div>
    </section>
    <section class="nds-rules site-responsive"
             v-if="ruleBlocks.length > 0 && !isStandMode">
          <div class="nds-rules__wrapper">
              <div
                  v-for="item in ruleBlocks"
                  class="nds-rules__rule"
              >
                  <img v-if="item.iconUrl" :src="item.iconUrl" class="nds-rules__image" />
                  <img v-else src="@/assets/img/tournaments/demo-stand/rules-default-icon.svg" class="nds-rules__image" />
                  <div class="nds-rules__description" v-html="item.description"></div>
              </div>
          </div>
      </section>
</template>

<script>
export default {
    name: "TournamentRulesSection",
    props: {
        tournament: {},
        isStandMode: {
          type: Boolean
        }
    },

    computed: {
        ruleBlocks () {
            const tournament = this.tournament || {};
            const data = [];
            let description;
            for (let i = 1; i <= 4; i++) {
                description = tournament[`rule_block_${i}_description`];
                if (description) {
                    data.push(
                        {
                            iconUrl: tournament[`rule_block_${i}_icon_url`],
                            description: description,
                        }
                    );
                }
            }
            return data;
        }
    }

}
</script>

