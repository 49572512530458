<template>
    <section class="nds-header">
        <div class="nds-header__wrapper" :class="{
                'site-responsive': !isDemoStand,
                'stand-responsive': isDemoStand,
                'nds-header__wrapper-is-player-auth': player && player.is_authorized,
                'nds-header__wrapper-is-player-non-auth': !player.is_authorized
            }">
            <!-- USERNAME -->
            <TournamentUsername v-if="!isDemoStand" :player="player" />
            <!-- DECOR: backgrounds -->
            <div class="nds-header__background_wrapper" :class="{
                    'site-responsive': !isDemoStand,
                    'stand-responsive': isDemoStand
                }">
                <div class="nds-header__background_shadow" :class="{
                        'site-responsive': !isDemoStand,
                        'stand-responsive': isDemoStand
                    }">
                    <div class="nds-header__background" :class="{
                            'site-responsive': !isDemoStand,
                            'stand-responsive': isDemoStand
                        }" :style="bannerStyle">
                        <div class="nds-header__titles" :class="{
                                'site-responsive': !isDemoStand,
                                'stand-responsive': isDemoStand
                            }">
                            <h1 class="nds-header__title" :class="{
                                    'site-responsive': !isDemoStand,
                                    'stand-responsive': isDemoStand
                                }">
                                <span class="nds-header__title-orange" v-if="tournamentTitle.single !== null">
                                    {{ tournamentTitle.single }}
                                </span>
                                <span class="nds-header__title-orange" v-if="tournamentTitle.single === null">
                                    {{ tournamentTitle.first }}
                                </span>
                                <span class="nds-header__title-white" v-if="tournamentTitle.single === null">
                                    {{ tournamentTitle.second }}
                                </span>
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
            <!-- LOGIN -->
            <TournamentLoginFormComponent
                v-if="!isDemoStand"
                :player="player"
                :tournament="tournament"
            />
            <!-- DECOR: brown angled block -->
            <div class="nds-header__brown_angle" :class="{
                    'site-responsive': !isDemoStand,
                    'stand-responsive': isDemoStand
                }"></div>
        </div>
        <TournamentTimePlateComponent
            :tournament="tournament"
            :date-locale="dateLocale"
            :date-time-zone="dateTimeZone"
            :is-player-auth="player.is_authorized"
            :is-demo-stand="isDemoStand"
        />
    </section>
</template>

<script>
import TournamentUsername from "@/pages/tournament/components/tournament_username.vue";
import TournamentTimePlateComponent from "@/pages/tournament/components/tournament_time_plate_component";
import TournamentLoginFormComponent from "@/pages/tournament/components/tournament_login_form_component";

export default {
    name: "TournamentHeaderSection",
    props: {
        tournament: {},
        player: {},
        dateLocale: {
            type: String,
            default: undefined,
        },
        dateTimeZone: {
            type: String,
            default: undefined,
        },
        isDemoStand: {
          type: Boolean
        }
    },
    components: {
        TournamentTimePlateComponent,
        TournamentLoginFormComponent,
        TournamentUsername
    },
    computed: {
        tournamentTitle() {
            const titles = {
                single: null,
                first: null,
                second: null
            }
            const rawTitle = this.tournament?.title || '3OAKS Tournament';
            const words = rawTitle.split(' ')

            if (words.length > 1) {
                return {
                    ...titles,
                    first: words.slice(0, -1).join(" "),
                    second: words[words.length - 1]
                }
            } else {
                return {
                    ...titles,
                    single: rawTitle
                }
            }
        },
        bannerStyle() {
            const bannerURL = this.tournament.banner_url;
            if (bannerURL) {
                return [
                    `background-image: radial-gradient(circle at center, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.97) 100%), url("${bannerURL}");`,
                    'background-size: cover;',
                    'background-repeat: no-repeat;',
                    'background-position: center center;'
                ].join(" ");
            }
            return ''
        },
    }
}
</script>

