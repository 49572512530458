<template>
    <div class="nds-header__username"
        :class="{'nds-header__username-active': isLogoutVisible}"
        v-if="player.is_authorized">
        <div class="nds-header__username-wrapper">
            <div class="nds-header__username-display-name" @click="onUserNameClick">
                <img src="../../../assets/img/tournaments/user-profile-plate-icon.png" :alt="player.name" />
                <span>{{ player.name }}</span>
            </div>
            <div class="nds-header__username-logout" v-if="isLogoutVisible">
                <span @click.prevent="onLogoutClick">Log out</span>
            </div>
        </div>
    </div>
</template>
<script>
import { appFetchJSON } from "@/lib/request_utils";

export default {
    name: 'TournamentUsername',
    props: {
        player: {
            required: true
        }
    },
    data() {
        return {
            isLogoutVisible: false
        }
    },
    methods: {
        onUserNameClick() {
            this.isLogoutVisible = !this.isLogoutVisible
        },
        onLogoutClick() {
            const url = `/tournaments/logout`;
            appFetchJSON(url)
                .then(res => {
                    this.$router.go();
                })
                .catch(this.processErrors);
        }
    }
}
</script>