<template xmlns="http://www.w3.org/1999/html">

  <main class="nds-wrapper" ref="root" v-if="isPreloadComplete">
    <div class="nds-wrapper__background-gradient" :class="{'ntp-is-stand-mode': isStandMode}">
      <div :class="{'nds-wrapper__background-trophy': isStandMode}">
        <TournamentHeaderSection
          :player="player"
          :tournament="tournament"
          :date-locale="dateLocale"
          :date-time-zone="dateTimeZone"
          :is-demo-stand="isStandMode"
        />

        <TournamentCommonPrizePoolSection
            :tournament="tournament"
            :is-demo-stand="isStandMode" />

        <TournamentPlayersGamesSection
            v-if="!isStandMode"
            :player="player"
            :tournament="tournament"
        />

        <TournamentRulesSection :tournament="tournament" :is-stand-mode="isStandMode" />

        <TournamentLeaderboardSection
            v-if="!isTournamentNew && isLeaderboardVisible"
            :leaderboard-data="leaderboardData"
            :date-locale="dateLocale"
            :date-time-zone="dateTimeZone"
            :is-stand-mode="isStandMode"
        />

        <TournamentPrizesListSection
            v-if="!isStandMode"
            :prizes-by-place="prizesByPlace"
            :date-locale="dateLocale"
            :date-time-zone="dateTimeZone"
        />

          <TournamentFooterSection
            v-if="!isStandMode"
            :tournament="tournament
          "/>

          <TournamentGamesListSection
              v-if="isStandMode"
              :tournament="tournament"
          />
      </div>
    </div>
  </main>
</template>

<script>

import {appFetchJSON, processFetchError} from "@/lib/request_utils";
import DateWidget from "@/components/date_widget";

import TournamentHeaderSection from "@/pages/tournament/sections/tournament_header_section";
import TournamentCommonPrizePoolSection from "@/pages/tournament/sections/tournament_common_prize_pool_section";
import TournamentRulesSection from "@/pages/tournament/sections/tournament_rules_section";
import TournamentGamesListSection from "@/pages/tournament/sections/tournament_games_list_section";
import TournamentLeaderboardSection from "@/pages/tournament/sections/tournament_leaderboard_section";

import TournamentPlayersGamesSection from "@/pages/tournament/sections/tournament_players_games_section";
import TournamentPrizesListSection from "@/pages/tournament/sections/tournament_prizes_list_section";
import TournamentFooterSection from "@/pages/tournament/sections/tournament_footer_section";
import TournamentTimePlateComponent from "@/pages/tournament/components/tournament_time_plate_component.vue";
import TournamentLeaderboardComponent from "@/pages/tournament/components/tournament_leaderboard_component";


const TOURNAMENT_STATUS_UPDATE_INTERVAL = 20000;

export default {
    name: "TournamentSite",
    props: {
        user: Object,
        isStandMode: {
            type: Boolean,
            default: false,
        },
    },

    components: {
      TournamentTimePlateComponent,
        DateWidget,
        TournamentLeaderboardComponent,
        TournamentHeaderSection,
        TournamentCommonPrizePoolSection,
        TournamentRulesSection,
        TournamentGamesListSection,
        TournamentLeaderboardSection,
        TournamentPlayersGamesSection,
        TournamentPrizesListSection,
        TournamentFooterSection,
    },

    data() {
        return {
            tournament: {},
            leaderboardData: [],
            isLeaderboardInited: false,
            prizesByPlace: [],
            isPrizePoolInited: false,
            player: {},
            currentGame: undefined,
            dateLocale: 'uk',
            dateTimeZone: 'UTC',
            isPreloadComplete: false,
        }
    },

    mounted() {
        const authPromise = this.auth();
        const tournamentPromise = this.fetchItem().then(() => authPromise);
        const preloaderCompletePromise = Promise.all(
            [authPromise, tournamentPromise]);
        preloaderCompletePromise
            .then(() => this.checkTournamentData())
            .then(() => {
                setInterval(() => {
                    this.checkTournamentData();
                }, TOURNAMENT_STATUS_UPDATE_INTERVAL);
            })
            .finally(() => {
                this.isPreloadComplete = true;
            })
    },

    computed: {

        tournamentTitle() {
            return this.tournament?.title || '3OAKS Tournament';
        },

        needUpdateLeaderboard() {
            const tournament = this.tournament;
            return (tournament && tournament.status === 'STARTED')
                || !this.isLeaderboardInited
        },

        needUpdatePrizePool() {
            const tournament = this.tournament;
            return (tournament && tournament.status === 'STARTED' && this.hasProgressivePrize)
                || !this.isPrizePoolInited
        },

        isTournamentNew() {
            const tournamentStatus = this.tournament?.status;
            return tournamentStatus === 'NEW'
                || tournamentStatus === 'PRE_NOTIFICATION';
        },

        isLeaderboardVisible() {
            return this.leaderboardData.length > 0;
        }
    },

    methods: {

        auth() {
            const url = `/tournaments/player`;
            return appFetchJSON(url)
                .then(res => {
                    this.player = res.data || {};
                })
                .catch(err => {
                    processFetchError(this.$router, err);
                });
        },

        fetchItem() {
            const tournamentNameOrDomain = this.$route.params.tournamentNameOrDomain;
            const url = `/tournaments/${tournamentNameOrDomain}`;
            const params = {};
            if (this.$route.query.domain) {
                params.domain = '1';
            }
            return appFetchJSON(url, {params: params, credentials: 'include'})
                .then(res => {
                    const tournament = res.data?.data;
                    if (!tournament) {
                        return res
                    }

                    const games = tournament?.games || [];
                    tournament.gamesMap = {};
                    games.forEach((item) => {
                        tournament.gamesMap[item['name']] = item;
                    });

                    this.tournament = tournament;
                })
                .catch(err => {
                    processFetchError(this.$router, err);
                });
        },

        sortByPlace(a, b) {
            const aPlace = a.place;
            const bPlace = b.place;
            if (aPlace > bPlace) return 1;
            if (aPlace < bPlace) return -1;
            return 0;
        },

        checkTournamentData() {
            if (this.checkTournamentDataPromise) {
                return;
            }
            const tournament = this.tournament;
            if (!tournament) {
                return;
            }

            const url = `/tournaments/public`;
            const params = {
                campaign_uid: tournament.campaign_uid,
                campaign_type: tournament.campaign_type,
                currency: tournament.currency,
                prize_pool: this.needUpdatePrizePool,
                leaderboard: this.needUpdateLeaderboard,
            }

            this.checkTournamentDataPromise = appFetchJSON(url, {params: params})
                .then((res) => {
                    const data = res.data || {};
                    if (data.status && data.status !== tournament.status) {
                        tournament.status = data.status
                    }

                    this.updatePrizePoolData(data['prize_pool']);
                    this.isPrizePoolInited = true;
                    this.updateLeaderboardData(data['leaderboard']);
                    this.isLeaderboardInited = true;
                })
                .finally(() => {
                    this.checkTournamentDataPromise = undefined;
                })
            return this.checkTournamentDataPromise;
        },

        updatePrizePoolData(data) {
            const prizes = data?.prizes;
            if (!Array.isArray(prizes) || prizes.length === 0) {
                return;
            }
            prizes.sort(this.sortByPlace);
            const tournament = this.tournament;
            prizes.forEach((item) => {
                const prize = item.prize;
                const prizeGameName = prize['game_name'];
                if (prizeGameName) {
                    prize['game_title'] = tournament.gamesMap?.[prizeGameName]?.['title_text'] || '';
                }

                const prizeType = prize.type;
                if (['MONEY', 'PROGRESSIVE', 'FREEBET'].indexOf(prizeType) !== -1) {
                    prize['currency'] = tournament.currency;
                    if (prizeType === 'PROGRESSIVE') {
                        this.hasProgressivePrize = true;
                        prize.progressive = data.progressive;
                    }
                }
            })
            this.prizesByPlace = prizes;
        },

        updateLeaderboardData(data) {
            // // mock
            // data = data || [];
            // const dataLen = data.length;
            // for (let i=0; i < 100; i++) {
            //     const item = {
            //         place: dataLen + i + 1,
            //         nick: `Player ${i + 1}`,
            //         score: Math.floor(Math.random() * 1000),
            //     };
            //     data.push(item);
            // }
            //
            // if (!Array.isArray(data) || data.length === 0) {
            //     return;
            // }


            data.forEach((item) => {
                item.place = parseInt(item.place);

                this.prizesByPlace.forEach(prizeItem => {
                    if (prizeItem.place === item.place) {
                        item.prize = prizeItem.prize;
                    }
                });

                const score = Math.floor(parseFloat(item.score * 100)) / 100;
                item.score = score.toFixed(2);
            });

            data.sort(this.sortByPlace);

            this.leaderboardData = data;
        }
    }
}
</script>
