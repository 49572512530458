<template>
    <section class="nds-prize-pool"
             v-if="tournament">
        <div class="nds-prize-pool__wrapper"
            :class="{
                'site-responsive': !isDemoStand,
                'stand-responsive': isDemoStand
            }">
            <h4 class="nds-prize-pool__title" :class="{
                    'site-responsive': !isDemoStand,
                    'stand-responsive': isDemoStand
                }">Prizepool</h4>
            <h3 class="nds-prize-pool__prize" :class="{
                    'site-responsive': !isDemoStand,
                    'stand-responsive': isDemoStand
                }">
                <span>
                    {{ tournament?.prize_pool_banner_text }}
                </span>
            </h3>
        </div>
    </section>
</template>

<script>
export default {
  name: "TournamentCommonPrizePoolSection",
  props: {
    tournament: {},
    isDemoStand: {
        type: Boolean
    }
  },
}
</script>

