<template xmlns:MoveLine="http://www.w3.org/1999/xlink">
  <main class="page campaigns-user">
    <div class="page__body campaigns-user__body">

      <div class="first-screen offset-section_small first-screen_full">
        <div class="_container">

          <!-- ==================================== BREADCRUMBS ========================================= -->
          <BreadCrumbs :links="breadCrumbsLinks"/>
          <!-- ==================================== BREADCRUMBS ========================================= -->

          <div class="first-screen__body">
            <div class="first-screen__left">
              <div class="page-heading page-heading_colors">
                <h1>
                  <span v-html="campaign.name"></span>
                  <span class="unpublished-text" v-if="campaign['is_visible'] === false">
                    (UNPUBLISHED)
                  </span>
                </h1>
              </div>
            </div>
            <div class="first-screen__right"></div>
          </div>
        </div>
      </div>


      <div class="_container">

        <!-- ======================================= PDF CARD CONTAINER ======================================= -->
        <div id="touch-slider-1" class="pdf-card-container touch-slider _swiper">
          <div class="swiper-wrapper">
            <DocumentsSlider :documents="campaign.documents"/>
          </div>
        </div>
        <!-- ======================================= PDF CARD CONTAINER ======================================= -->

        <!-- ======================================= ANCHORS BLOCK ======================================= -->
        <div class="offset-section anchors-section">
          <div class="anchors-section__title">
            <span>JUMP TO:</span>
          </div>
          <div class="anchors-section__links">
            <a href="#description-anchor"
               @click="onAnchorClick">Description</a>
            <a href="#general-information-anchor"
               @click="onAnchorClick">General Information</a>
            <a href="#games-anchor"
               @click="onAnchorClick">Games</a>
            <a href="#prize-distribution-anchor"
               @click="onAnchorClick">Prize Distribution</a>
          </div>
        </div>
        <!-- ======================================= ANCHORS BLOCK ======================================= -->


        <!-- ======================================= DESCRIPTION SECTION ======================================= -->
        <div id="_description-anchor" class="offset-section description-section">
          <h6 class="offset-title">DESCRIPTION</h6>
          <div class="_container-description _container-description_reverse">
            <div class="column">

              <div class="_text-box" v-html="campaign.description"></div>

            </div>
            <div class="column">

              <div class="description-section__card _ibg _with-bg">
                <img :src="campaign.logo" alt="3oaks">
              </div>

            </div>
          </div>
        </div>
        <!-- ======================================= DESCRIPTION SECTION ======================================= -->

      </div>

      <div id="_general-information-anchor">
        <MoveLine :title="'GENERAL INFORMATION'">
          <span>specifications</span>
          <span><img src="@/assets/img/svg/move-1.svg" alt="3oaks"></span>
          <span>specifications</span>
          <span><img src="@/assets/img/svg/move-2.svg" alt="3oaks"></span>
          <span>specifications</span>
          <span><img src="@/assets/img/svg/move-3.svg" alt="3oaks"></span>
        </MoveLine>
      </div>

      <!-- ======================================= GAME TABLE SECTION ======================================= -->
      <div id="two" class="_container offset-section game-table-section params-table">
        <table class="params-table">
          <tr>
            <td>
              <div class="game-table__lable">Discipline</div>
            </td>
            <td>
              <div class="game-table__value">{{ campaign['disciplines'] }}</div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="game-table__lable">Starts At</div>
            </td>
            <td>
              <div class="game-table__value">
                <DateWidget :iso-date-string="campaign['start_date']" :options="dateOptions"/>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="game-table__lable">Finishes At</div>
            </td>
            <td>
              <div class="game-table__value">
                <DateWidget :iso-date-string="campaign['end_date']" :options="dateOptions"/>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="game-table__lable">Prize Pool</div>
            </td>
            <td>
              <div class="game-table__value">{{ campaign['prize_pool'] }}</div>
            </td>
          </tr>
        </table>
      </div>
      <!-- ======================================= GAME TABLE SECTION ======================================= -->

      <!-- =================================== USER SLIDER SECTION ===================================  -->
      <section id="_games-anchor" class="offset-section _container">
        <h6 class="offset-title">GAMES</h6>

        <!-- PAGE SLIDER -->
        <div class="page-slider page-slider_user">
          <div class="page-slider__view">
            <div id="page-slider_user-1" class="page-slider__slider _swiper" ref="swiper">
              <div class="swiper-wrapper">
                <div class="page-slider__slide swiper-slide" v-for="item of campaign.games">

                  <div class="game-card">
                    <a class="game-card__body" :href="item.url">
                      <div class="game-card__image _ibg">
                        <img :src="item.logo" alt="3oaks">
                      </div>
                      <div class="game-card__description _icon-arrow-3">
                        <p v-html="item.title"></p>
                      </div>
                    </a>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div class="page-slider__controls">
            <div class="page-slider__nav">
              <div class="page-slider__progressbar">
                <div class="page-slider_user-1__progressbar" ref="progressbar"></div>
              </div>
              <div class="page-slider__buttons">
                <div class="page-slider__prev-button page-slider_user-1__prev _icon-arrow-1"
                     ref="prevSlideButton"></div>
                <div class="page-slider__next-button page-slider_user-1__next _icon-arrow-1"
                     ref="nextSlideButton"></div>
              </div>
            </div>
          </div>
        </div>

      </section>
      <!-- =================================== USER SLIDER SECTION ===================================  -->

      <!--===================== PRIZE TABLE =====================-->
      <div id="_prize-distribution-anchor" class="offset-section prize-table-section">

        <div class="_container">
          <h6 class="offset-title full-width" offset="20%">PRIZE DISTRIBUTION</h6>
        </div>

        <div class="prize-table-container _container" v-html="campaign['prize_distribution']">

        </div>
      </div>
      <!--===================== PRIZE TABLE =====================-->

    </div>
  </main>
</template>

<script>

import BreadCrumbs from "@/components/breadcrumbs";
import DocumentsSlider from '@/components/documents_slider';
import MoveLine from "@/components/move_line";
import Swiper from "@/assets/js/Swiper";
import {appFetchJSON} from "@/lib/request_utils";
import DateWidget from "@/components/date_widget";

export default {
    name: "Campaign",
    components: {
        DateWidget,
        DocumentsSlider,
        BreadCrumbs,
        MoveLine
    },
    data() {
        return {
            breadCrumbsLinks: [{title: 'Campaigns', href: '/client-area/campaigns'}],
            campaign: {},
            dateOptions: {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                timeZone: 'UTC',
                timeZoneName: 'short'
            }
        }
    },

    mounted() {
        this.fetchCampaign().then(() => {
            this.updateBreadCrumbs();
            this.initSlider();
        });
    },

    methods: {
        fetchCampaign() {
            let url = `/campaigns/${this.$route.params.name}`;
            return appFetchJSON(url, {})
                .then((res) => {
                    const campaign = res.data.item;
                    const discipline = campaign['disciplines'] || [];
                    campaign['disciplines'] = discipline.join(', ');
                    const games = campaign.games || [];
                    games.forEach((game) => {
                        let routeParams = {name: 'client-area-game', params: {name: game.slug}};
                        game.url = this.$router.resolve(routeParams).fullPath;
                        game.title = game['title_en'];
                    });
                    const documents = campaign.documents || [];
                    documents.forEach((doc) => {
                        if (doc.type === 'campaign_tc') doc.title = 'Terms & Conditions';
                        else if (doc.type === 'campaign_promo') doc.title = 'Promo Pack'
                    });
                    documents.sort((a, b) => {
                        if (a.type === 'campaign_tc') return -1;
                        if (b.type === 'campaign_tc') return 1;
                        if (a.type === b.type && a.type === 'campaign_tc') return 0;
                        if (a.title > b.title) return 1;
                        if (a.title < b.title) return -1;
                        return 0;
                    });
                    this.campaign = campaign;
                })
                .catch((err) => {
                    let resp = err.response;
                    if (resp.status === 404) {
                        this.$router.push({'name': 'page-not-found'});
                    }
                });
        },

        updateBreadCrumbs() {
            this.breadCrumbsLinks = [
                {title: 'Campaigns', href: '/client-area/campaigns'},
                {title: this.campaign.name}
            ];
        },

        onAnchorClick(e) {
            let hash = e.target.hash.slice(1);
            this.gotoAnchor(hash);
        },

        gotoAnchor(hash) {
            let el = this.$el.querySelector('#_' + hash)
            if (!el) {
                return;
            }
            let rect = el.getBoundingClientRect()
            setTimeout(() => {
                window.scrollTo({
                    top: rect.top + window.pageYOffset,
                    behavior: 'smooth'
                });
            }, 50);
        },
        initSlider() {
            if (this.swiper) return;
            this.swiper = new Swiper(this.$refs.swiper, {
                observer: true,
                speed: 900,
                observeParents: true,
                slidesPerView: 1.3,
                autoHeight: true,
                grabCursor: true,
                slideActiveClass: '_active-slide',
                navigation: {
                    prevEl: this.$refs.prevSlideButton,
                    nextEl: this.$refs.nextSlideButton,
                    disabledClass: '_disabled',
                },
                pagination: {
                    el: this.$refs.progressbar,
                    type: 'progressbar',
                },

                breakpoints: {
                    320: {
                        spaceBetween: 16,
                        slidesPerView: 1.3,
                    },
                    480: {
                        spaceBetween: 24,
                        slidesPerView: 2.2,
                    },
                    780: {
                        spaceBetween: 32,
                        slidesPerView: 3.5,
                    },
                    992: {
                        spaceBetween: 40,
                        slidesPerView: 4.5,
                    },
                    1920: {
                        spaceBetween: 48,
                        slidesPerView: 6.5,
                    }
                }
            });
        }
    }
}
</script>