<template>
  <div v-if="!player.is_authorized" class="nds-login-form site-responsive" id="tournament-login-form">
    <h4 class="nds-login-form__title">Join tournament</h4>
      <label class="nds-login-form__label">
        <span v-if="errors.name" class="nds-login-form__errors">{{ errors.name }}</span>
        <input type="text" class="nds-login-form__input" placeholder="Nickname" v-model="name"/>
      </label>
      <label class="nds-login-form__label">
        <span v-if="errors.email" class="nds-login-form__errors">{{ errors.email }}</span>
        <input type="text"  class="nds-login-form__input" placeholder="Email" v-model="email">
      </label>
      <div v-if="errorsCommon" class="nds-login-form__errors">{{ errorsCommon }}</div>
      <div class="nds-login-form__signin">
        <button class="nds-login-form__signin-button" @click.prevent="onLoginButtonClick()">Log In</button>
      </div>
  </div>
</template>

<script>

import { appFetchJSON, processFetchError } from "@/lib/request_utils";
import TournamentTimePlateComponent from "@/pages/tournament/components/tournament_time_plate_component.vue";

export default {
    name: "TournamentLoginFormComponent",
    components: {TournamentTimePlateComponent},

    props: {
        player: Object,
        tournament: Object,
    },

    mounted() {

    },

    data() {
        return {
            activeTabIndex: 0,
            name: '',
            email: '',
            company: '',
            errors: {
                name: '',
                email: '',
                company: ''
            },
            errorsCommon: '',
            isUserMenuExtended: false
        }
    },

    methods: {
        onTabClick(tabIndex) {
            this.activeTabIndex = tabIndex;
            this.resetErrors();
        },

        onUserMenuClick() {
          this.isUserMenuExtended = !this.isUserMenuExtended
        },

        onLoginButtonClick() {
            const url = `/tournaments/login-or-signup`;
            const options = {
                params: {
                    tournament_uid: this.tournament?.uid,
                    email: this.email,
                    name: this.name,
                }
            }
            appFetchJSON(url, options)
                .then(res => {
                    this.$router.go();
                })
                .catch(this.processErrors);
        },

        onSignUpButtonClick() {
            const url = `/tournaments/signup`;
            const options = {
                params: {
                    tournament_uid: this.tournament?.uid,
                    email: this.email,
                    name: this.name,
                    company: this.company,
                }
            }
            appFetchJSON(url, options)
                .then(res => {
                    this.$router.go();
                })
                .catch(this.processErrors);
        },

        onLogoutClick() {
            const url = `/tournaments/logout`;
            appFetchJSON(url)
                .then(res => {
                    this.$router.go();
                })
                .catch(this.processErrors);
        },

        processErrors(err) {
            console.log(err);
            this.resetErrors();
            const res = err.response;
            if (res) {
                res.json().then((data) => {
                    const errors = (data || {}).errors;
                    if (typeof errors === 'string' || errors instanceof String) {
                        this.errorsCommon = errors;
                        return;
                    }

                    if (Array.isArray(errors)) {
                        this.errorsCommon = errors[0];
                        return;
                    }

                    if (errors) {
                        try {
                            for (let [key, val] of Object.entries(errors)) {
                                if (Array.isArray(val)) {
                                    val = val[0];
                                }
                                this.errors[key] = val;
                            }
                        } catch (err) {
                            processFetchError(this.$router, err);
                        }
                        return;
                    } else {
                        processFetchError(this.$router, err);
                    }
                });
            } else {
                processFetchError(this.$router, err);
            }
        },

        resetErrors() {
            this.errors = {};
            this.errorsCommon = '';
        }
    }

}
</script>

<style scoped></style>