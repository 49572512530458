<template>
  <ul>
    <li>
      <a href="https://www.facebook.com/3-Oaks-Gaming-108423585065971" target="_blank" rel="nofollow noopener noreferrer">
        Facebook
      </a>
    </li>
    <li>
      <a href="https://www.linkedin.com/company/3-oaks-gaming" target="_blank" rel="nofollow noopener noreferrer">
        LinkedIn
      </a>
    </li>
    <li>
      <a href="https://www.youtube.com/@3OaksGaming" target="_blank" rel="nofollow noopener noreferrer">
        Youtube
      </a>
    </li>
  </ul>
</template>


<script>
export default {
    name: "SocialLinks"
}
</script>
