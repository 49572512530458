<template>
  <div class="nds-common__container-y" v-if="isVisible">
      <h2 class="nds-common__title">
        <span>Prizes</span>
      </h2>
      <div class="nds-common__container-x nds-tables">
          <div class="nds-tables__wrapper nds-prizes">
              <table class="">
                <thead>
                  <tr>
                    <th></th>
                    <th class="nds-cell-prizes-place">
                        #
                    </th>
                    <th class="nds-cell-prizes-prize">
                        Prize
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in groupedPrizesToDisplay"
                    :class="{'top3': item.index <= 3, 'top10': item.index >= 4}">
                      <td class="nds-cell-prizes-icon">
                        <img v-if="item.index === 1" src="@/assets/img/tournaments/medal-1.png" class="nds-table-icon"/>
                        <img v-if="item.index === 2" src="@/assets/img/tournaments/medal-2.png" class="nds-table-icon"/>
                        <img v-if="item.index === 3" src="@/assets/img/tournaments/medal-3.png" class="nds-table-icon"/>
                        <img v-if="item.index >= 4" src="@/assets/img/tournaments/medal-common.png" class="nds-table-icon"/>
                      </td>
                      <td class="nds-cell-prizes-place">
                        {{ item.place }}
                      </td>
                        <td class="nds-cell-prizes-prize">
                            <TournamentPrizeComponent
                              :prize="item.prize"
                              :date-locale="dateLocale"
                              :date-time-zone="dateTimeZone"
                          />
                        </td>
                  </tr>
                </tbody>
              </table>
          </div>
      </div>
    </div>
</template>

<script>

import TournamentPrizeComponent from "@/pages/tournament/components/tournament_prize_component";

export default {
    name: "TournamentPrizesListSection",
    components: {
      TournamentPrizeComponent
    },
    props: {
        prizesByPlace: {
            type: Object,
            default: [],
        },
        dateLocale: {
            type: String,
            default: undefined,
        },
        dateTimeZone: {
            type: String,
            default: undefined,
        }
    },

    computed: {
        isVisible() {
            const prizesByPlace = this.prizesByPlace;
            return Array.isArray(prizesByPlace) && prizesByPlace.length > 0;
        },

        prizesToDisplay() {
            return [...this.prizesByPlace].map((item, index) => {
                item.place = index+1
                item.index  = index+1
                return item
            })
        },

        groupedPrizesToDisplay() {
            const data = [...this.prizesByPlace]
            const result = []
            let currentGroup = []
            let currentValue = null
            let startPlace = null

            data.forEach((item) => {
                if (item.place <= 3) {
                    result.push({
                        place: item.place.toString(),
                        prize: {...item.prize}
                    })
                } else {
                    if (currentValue !== item.prize.value) {
                        if (currentGroup.length > 1) {
                            result.push({
                                place: `${startPlace}-${startPlace + currentGroup.length - 1}`,
                                prize: {...currentGroup[0].prize, value: currentValue}
                            });
                        } else if (currentGroup.length === 1) {
                            result.push({
                                place: startPlace.toString(),
                                prize: {...currentGroup[0].prize, value: currentValue}
                            })
                        }

                        currentGroup = [item]
                        currentValue = item.prize.value
                        startPlace = item.place
                    } else {
                        currentGroup.push(item)
                    }
                }
            })

            if (currentGroup.length > 1) {
                result.push({
                    place: `${startPlace}-${startPlace + currentGroup.length - 1}`,
                    prize: {...currentGroup[0].prize, value: currentValue}
                })
            } else if (currentGroup.length === 1) {
                result.push({
                    place: startPlace.toString(),
                    prize: {...currentGroup[0].prize, value: currentValue}
                })
            }

            return result.map((item, index) => {
                item.index = index+1
                return item
            })
        }
    },
}
</script>

<style scoped>

</style>