<template>
  <section v-if="currentGame !== undefined">
    <div class="nds-player-games">
      <h2 class="nds-common__title">
        <span>Games</span>
      </h2>
      <div class="nds-common__container-x">
          <div class="nds-player-games__banner-container">
              <div class="nds-player-games__game-container" v-if="isGameRunnerVisible">
                  <GameRunner
                      v-if="isGameRunnerVisible"
                      :banner="currentGame.banner_file"
                      :languages="currentGame.languages"
                      :gameUrl="currentGame.game_url"
                      :lang="this.$route.query.lang || 'en'"
                      :open-in-new-tab="true"
                  />
                </div>
                <div class="nds-player-games__game-banner" v-if="!isGameRunnerVisible">
                  <img :src="currentGame.banner_file"/>
                  <div v-if="!isTournamentPlayable"
                      class="nds-player-games__game-banner-info-text">
                    Current campaign has been finished. <br />
                    You may find more information about our products at <a :href="siteUrl" class="nds-common__link">3Oaks</a>.<br/>
                    Until the next time!
                  </div>
                </div>
          </div>
      </div>
      <div
          v-if="!player.is_authorized && isTournamentPlayable"
          class="nds-player-games__game-banner-info-text">
        Please <span class="nds-common__no-link" @click.prevent="onGameSectionLoginClick">login</span> to participate.
      </div>
      <div v-if="player.is_authorized && isTournamentPlayable" class="nds-player-games__game-banner-info-text">
        Select the game
      </div>
      <div class="nds-player-games__slider-host" v-if="isGamesSliderVisible"  :key="componentKey">
          <div class="swiper nds-player-games__games-thumbs" ref="swiper">
            <div class="swiper-wrapper nds-player-games__games-thumbs-wrapper">
              <div
                  v-for="game in gamesChunked"
                  class="swiper-slide nds-player-games__games-thumbs-slide"
              >
                  <img
                      class="nds-player-games__game-list-image"
                      :src="game.banner_file"
                      :class="{'active': game.name === currentGame.name}"
                  />
              </div>
            </div>
            <div class="nds-player-games__prev-slide" :style="{ left: getSwiperButtonsOffset }">
              <img src="@/assets/img/tournaments/game-list-arrow.png" alt="" />
            </div>
            <div class="nds-player-games__next-slide" :style="{ right: getSwiperButtonsOffset }">
              <img src="@/assets/img/tournaments/game-list-arrow.png" alt="" />
            </div>
          </div>
        </div>
    </div>
  </section>
</template>

<script>

import GameRunner from "@/components/game_runner";
import Swiper from "@/assets/js/Swiper";
import {settings} from "@/lib/settings";
import {nextTick} from "vue";

const TOURNAMENTS_PLAYABLE_STATUSES = ['NEW', 'PRE_NOTIFICATION', 'STARTED'];

export default {
    name: "TournamentPlayersGamesSection",
    components: {
        GameRunner,
    },
    props: {
        player: Object,
        tournament: Object,
    },

    // emits: ['onLoginClick'],

    data() {
        return {
            currentGame: undefined,
            swiperOneRowMode: false,
            componentKey: 0,
            slidesPerView: 3
        }
    },

    watch: {
        'tournament.main_game'() {
            this.initCurrentGame();
        },

        'tournament.games'() {
            this.initSlider();
        },

        componentKey() {
            this.$nextTick(() => {
              this.initSlider()
            })
        }
    },

    created() {
        window.addEventListener("resize", this.onResize);
    },

    destroyed() {
        window.removeEventListener("resize", this.onResize);
    },

    computed: {
        siteUrl() {
            const {protocol, port} = window.location;
            const portPostfix = port === '80' ? `:${port}` : '';
            return `${protocol}//${settings.SERVICE_NAME}${portPostfix}`;
        },

        isGameRunnerVisible() {
            if (!this.player.is_authorized) {
                return false;
            }
            return this.isTournamentPlayable;
        },

        isTournamentPlayable() {
            const tournamentStatus = this.tournament.status;
            return TOURNAMENTS_PLAYABLE_STATUSES.indexOf(tournamentStatus) >= 0;
        },

        isOneGameMode() {
            const tGames = this.tournament?.games || [];
            return tGames.length === 1;
        },

        isGamesSliderVisible() {
            const tournamentGames = this.tournament?.games || [];
            return tournamentGames.length > 1;
        },

        gamesChunked() {
            // rerender component hack
            this.componentKey;
            const result = [];
            const tGames = [...this.tournament?.games] || [];
            // mock
            // if (tGames.length) {
            //     for (let l = 0; l < 30; l++) {
            //         tGames.push(tGames[l]);
            //     }
            // }

            // mock
            // function randomNameGen() {
            //     const getRandomLetters = (length) => {
            //         const letters = "abcdefghijklmnopqrstuvwxyz";
            //         return Array.from({ length }, () => letters[Math.floor(Math.random() * letters.length)]).join('');
            //     };
            //
            //     const firstPart = getRandomLetters(5); // e.g., "oplkm"
            //     const secondPart = getRandomLetters(4); // e.g., "ddop"
            //     return `${firstPart}_${secondPart}`;
            // }
            //
            // for (let i=0; i<20; i++) {
            //   const mockedGame = {...tGames[0], name: randomNameGen()}
            //   tGames.push(mockedGame)
            // }
            return tGames;



            // this.swiperOneRowMode = this.isOneRowSwiperMode(tGames)

            // if (this.swiperOneRowMode) {
            //     for (let i = 0; i <= tGames.length - 1; i++) {
            //         result.push([tGames[i]]);
            //     }
            // } else {
            //     for (let i = 1, ri = 0; i <= tGames.length; i++) {
            //         if (result.length < ri + 1) {
            //             result.push([]);
            //         }
            //         result[ri].push(tGames[i - 1]);
            //         if (i % 3 === 0) {
            //             ri++;
            //         }
            //     }
            // }
            // return result;
        },

        getSwiperButtonsOffset() {
          switch (this.slidesPerView) {
            case 5:
              return `calc((100% / ${this.slidesPerView}) + ((100% / ${this.slidesPerView}) / 2))`
            case 7:
              return `calc(((100% / ${this.slidesPerView}) * 2) + ((100% / ${this.slidesPerView}) / 2))`
            default:
              return `calc(100% / 5)`
          }
        }
    },

    mounted() {
        this.initCurrentGame();
        this.initSlider();
    },

    methods: {

        initCurrentGame() {
            if (this.currentGame === undefined) {
                const mainGame = this.getMainGame();
                this.setCurrentGame(mainGame);
            }
        },

        getMainGame() {
            const tnmt = this.tournament || {};
            let game = tnmt['main_game'];
            if (game) {
                return game;
            }

            const tGames = tnmt.games || [];
            if (tGames.length) {
                game = tGames[0];
            }
            return game;
        },

        onGameBannerClick(game) {
            if (this.currentGame.name === game.name) {
                return;
            }
            this.setCurrentGame(game);
        },

        setCurrentGame(game) {
            this.currentGame = game;
        },

        onGameSectionLoginClick() {
            // this.$emit('onLoginClick');
            const formElement = document.getElementById('tournament-login-form');
            if (formElement) {
              formElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        },

        isOneRowSwiperMode(gamesList) {
            // return window.innerWidth <= 992 && gamesList.length < 9;
            return true;
        },

        isOneSlideSwiperMode(gamesList) {
            return gamesList.length === 1;
        },

        onResize() {
            if (this.resizeTimeout) {
                clearInterval(this.resizeTimeout);
            }
            this.resizeTimeout = setTimeout(() => {
                this.componentKey++;
                // this.initSlider();
                this.resizeTimeout = undefined;
            }, 600);

        },

        updateSlidesPerView(swiper) {
          this.slidesPerView = swiper.params.slidesPerView;
        },

        applyOpacity(swiper) {
          swiper.slides.forEach((slide) => {
            slide.style.opacity = "0.3";
          });

          const activeIndex = swiper.activeIndex;

          // Set opacity based on distance from active slide
          swiper.slides.forEach((slide, index) => {
            const distance = Math.abs(activeIndex - index);
            switch (distance) {
              case 0:
                slide.style.opacity = "1";   // Active slide
                break;
              case 1:
                slide.style.opacity = "0.6"; // Immediate neighbors
                break;
              case 2:
                slide.style.opacity = "0.4"; // Second neighbors
                break;
              case 3:
                slide.style.opacity = "0.3"; // Third neighbors
                break;
              default:
                slide.style.opacity = "0.3"; // Other slides
                break;
            }
          });
        },

        updateActiveGame() {
          if (this.swiper) {
            const activeIndex = this.swiper.realIndex;
            const game = this.gamesChunked[activeIndex];

            if (!game) {
              return;
            }

            if (this.currentGame.name === game.name) {
              return;
            }
            this.setCurrentGame(game);
          }
        },

        initSlider() {
            if (this.swiper) {
                this.swiper.destroy();
            }
            if (!this.isGamesSliderVisible) {
                return;
            }
            nextTick(() => {
                const mainGame = this.getMainGame()
                const mainGameIndex = this.gamesChunked.findIndex(game => game.name === mainGame.name)

                this.swiper = new Swiper(
                    this.$refs.swiper,
                    {
                      observe: true,
                      observeParents: true,
                      slidesPerView: this.gamesChunked.length <= 3 ? "auto" : 3,
                      initialSlide: this.gamesChunked.length >= 3 ? 2 : 1,
                      centeredSlides: true,
                      loop: this.gamesChunked.length > 3,
                      simulateTouch: true,
                      grabCursor: true,
                      // autoHeight: true,
                      // calculateHeight: true,
                      // scrollbar: {
                      //     el: this.$refs.swiperScrollbar,
                      //     draggable: false,
                      //     // dragSize: dragSize
                      // },
                      breakpoints: {
                        768: {
                          slidesPerView: 5,
                        },
                        1024: {
                          slidesPerView: 7,
                        },
                      },
                      navigation: {
                        nextEl: ".nds-player-games__next-slide",
                        prevEl: ".nds-player-games__prev-slide",
                      },
                      on: {
                        breakpoint: (swiper) => {
                          this.updateSlidesPerView(swiper);
                        },
                        init: (swiper) => {
                          this.updateSlidesPerView(swiper);
                          if (mainGameIndex !== undefined) {
                            if (this.gamesChunked.length > 3) {
                              swiper.slideToLoop(mainGameIndex, 0);
                            } else {
                              swiper.slideTo(mainGameIndex, 0);
                            }
                          }
                          this.applyOpacity(swiper);
                        },
                        slideChange: (swiper) => {
                          this.updateActiveGame();
                          this.applyOpacity(swiper);
                        },
                      },
                    }
                );
            });
        },
    }
}
</script>

<style scoped>
</style>