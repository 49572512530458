<template>
  <div class="nds-footer">
      <h2 class="nds-common__title in-footer">
        <span>Contacts</span>
      </h2>
      <div class="nds-footer__content">
        <div class="nds-footer__footer-col">
          <div class="nds-footer__contacts-content">
            <div v-html="tournament.contact_information"></div>
            <p>© {{ year }} 3 Oaks Gaming. All Rights Reserved</p>
          </div>
        </div>
        <div class="nds-footer__footer-col">
          <div class="nds-footer__footer-social-links">
            <a href="https://www.facebook.com/3-Oaks-Gaming-108423585065971"
               target="_blank"
               rel="nofollow noopener noreferrer"
               class="nds-footer__footer-social-link">
              <img src="@/assets/img/tournaments/social-facebook-icon.svg" alt="Facebook link" />
            </a>
            <a href="https://www.linkedin.com/company/3-oaks-gaming"
               target="_blank"
               rel="nofollow noopener noreferrer"
               class="nds-footer__footer-social-link">
              <img src="@/assets/img/tournaments/social-linkedin-icon.svg" alt="LinkedIn link" />
            </a>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
    name: "TournamentFooterSection",
    props: {
        tournament: {},
    },

    data() {
        return {
            year: new Date().getFullYear()
        }
    },
}
</script>

