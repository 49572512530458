<template>
    <div class="nds-common__container-x nds-tables">
        <div class="nds-tables__wrapper nds-leaderboard" :class="{'nds-is-stand-mode': isStandMode}">
          <table>
            <thead>
              <tr>
                <th class="nds-cell-place"></th>
                <th class="nds-cell-place">
                    #
                </th>
                <th class="nds-cell-player">
                    Player
                </th>
                <th class="nds-cell-score">
                    Score
                </th>
                <th class="nds-cell-prize">
                    Prize
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in leaderboardData"
                :class="{top3: item.place <= 3, top10: item.place >= 4 && item.place <= 10}">
                <td class="nds-cell-prizes-icon">
                    <img v-if="item.place === 1" src="@/assets/img/tournaments/medal-1.png" class="nds-table-icon" />
                    <img v-if="item.place === 2" src="@/assets/img/tournaments/medal-2.png" class="nds-table-icon" />
                    <img v-if="item.place === 3" src="@/assets/img/tournaments/medal-3.png" class="nds-table-icon" />
                    <img v-if="item.place >= 4 && item.place <= 10" src="@/assets/img/tournaments/medal-common.png" class="nds-table-icon" />
                </td>
                <td class="nds-cell-place">
                    {{ item.place }}
                </td>
                <td class="nds-cell-player">
                    {{ item.nick }}
                </td>
                <td class="nds-cell-score">
                    {{ item.score }}
                </td>
                <td class="nds-cell-prize">
                    <TournamentPrizeComponent :prize="item.prize"
                     :date-locale="dateLocale"
                     :date-time-zone="dateTimeZone" />
                </td>
              </tr>
            </tbody>
          </table>
      </div>
    </div>
</template>

<script>
import TournamentPrizeComponent from "@/pages/tournament/components/tournament_prize_component";

export default {
    name: "TournamentLeaderboardComponent",

    components: {
        TournamentPrizeComponent,
    },

    props: {
        leaderboardData: {
            type: Array,
            default: [],
        },
        dateLocale: {
            type: String,
            default: undefined,
        },
        dateTimeZone: {
            type: String,
            default: undefined,
        },
        isStandMode: {
            type: Boolean
        }
    },
}
</script>

<style scoped>

</style>