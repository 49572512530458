<template>
    <div class="nds-common__container-y">
      <h2 class="nds-common__title">
        <span>Leaderboard</span>
      </h2>
      <TournamentLeaderboardComponent
        :leaderboard-data="leaderboardData"
        :date-locale="dateLocale"
        :date-time-zone="dateTimeZone"
        :is-stand-mode="isStandMode"
      />
    </div>
</template>

<script>

import TournamentLeaderboardComponent from "@/pages/tournament/components/tournament_leaderboard_component";


export default {
    name: "TournamentLeaderboardSection",
    props: {
        leaderboardData: {
            type: Array,
            default: [],
        },
        dateLocale: {
            type: String,
            default: undefined,
        },
        dateTimeZone: {
            type: String,
            default: undefined,
        },
        isStandMode: {
            type: Boolean
        }
    },
    components: {
        TournamentLeaderboardComponent,
    }
}
</script>

